import { css } from "@emotion/react";
import { theme } from "@xometry/xometry_loft";

export const rfqWizardWrapper = css`
  border-radius: 3px;
  box-shadow: 0px 1px 4px 0px #C1CAD1;
  display: flex;
  flex-direction: column;
  background-color: white;
  min-width: 700px;
  max-width: 933px;
  margin-bottom: 20px;
  padding: 20px;
`;

export const quotingRow = css`
  display: flex;
  flex-direction: row;
`;

export const quoteUploadWrapper = css`
  position: relative;
  width: 100%;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
  }
  &::after {
    content: "Or";
    position: absolute;
    top: 45%;
    right: -30px;
    padding: 3px;
    border-radius: 100px;
  }
`;
export const quoteWizardWithRQContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    height: 80px;
    padding-top: 32px;
  }
`;

export const instantQuoteHeading = css`
  font-size: 14px;
  font-weight: ${theme.fontWeights.semibold};
  position: absolute;
  top: 10px;
  left: 15px;
`;

export const quoteWizardText = css`
  font-weight: ${theme.fontWeights.bold};
`;

export const requestQuoteContainer = css`
  margin-left: 25px;
  max-width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  cursor: pointer;
  color: ${theme.colors.darkBlues[75]};
  i {
    color: ${theme.colors.blues[100]};
    font-size: 28px;
    padding-top: 25px;
  }
  button {
    margin: 23px 0 25px;
  }
  .rfq-header {
    font-size: ${theme.fontSizes[1]};
    font-weight: ${theme.fontWeights.semibold};
    color: ${theme.colors.grays[0]};
    margin-left: 8px;
  }
  border: none;
`;

export const requestQuoteText = css`
  text-align: center;
  padding-left: 5px;
  font-size: 13px;
`;

export const moreInfoText = css`
  text-align: center;
  margin-top: 15px;
`;

export const chooseFileButtonCss = css`
  background-color: transparent;
  border: none;
  color: ${theme.colors.blues[100]};
  &:hover {
    background-color: transparent;
    color: ${theme.colors.blues[100]};
  }
  &:focus {
    background-color: transparent !important;
    box-shadow: none !important;
    color: ${theme.colors.blues[100]} !important;
  }
`;
