// eslint-disable-next-line
import React, { useState } from "react";
import { Panel, Button, Label, Modal } from "@xometry/xometry_loft";
import { jsx } from "@emotion/react";
/** @jsx jsx */
import { useMutation } from "@apollo/client";
import { shareRequestModalStyle } from "./QuoteSearch.style";
// import { genericTrack } from "../../utils/analytics";
import { SendShareRequestQuery } from "../../schema/generated/schema";

type ShareRequestModalArgs = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  quoteId: string;
};

export default function ShareRequestModal({
  isOpen,
  onClose,
  onSubmit,
  quoteId,
}: ShareRequestModalArgs) {
  const [sendShareRequest, { loading: sendShareRequestLoading }] = useMutation(
    SendShareRequestQuery
  );
  const [note, setNote] = useState("");
  const [showError, setShowError] = useState(false);
  return (
    <>
      <div id="ShareRequestModal" />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        selector="#ShareRequestModal"
        padding="0"
        css={shareRequestModalStyle}
      >
        <div>
          <h3>{`Request Access To Quote ID ${quoteId}`}</h3>
          <Panel>
            <div>
              {showError && (
                <div className="feedback">
                  A note for the quote owner is required.
                </div>
              )}
              <Label htmlFor="share-request-note">Note:</Label>
              <textarea
                rows={4}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  setShowError(false);
                  setNote(e.target.value);
                }}
                disabled={sendShareRequestLoading}
                id="share-request-note"
              />
            </div>
            <div className="button-bar">
              <Button
                kind="text"
                onClick={onClose}
                disabled={sendShareRequestLoading}
              >
                Cancel
              </Button>
              <Button
                kind="primary"
                disabled={sendShareRequestLoading}
                onClick={() => {
                  if (!note.length) {
                    setShowError(true);
                    return;
                  }
                  sendShareRequest({
                    variables: {
                      quoteId,
                      note,
                    },
                  })
                    .then(() => {
                      onSubmit();
                      onClose();
                    })
                    .catch(() => {
                      onSubmit();
                      onClose();
                    });
                }}
              >
                {sendShareRequestLoading ? "Sending..." : "Request Access"}
              </Button>
            </div>
          </Panel>
        </div>
      </Modal>
    </>
  );
}
