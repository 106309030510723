import { css } from "@emotion/react";
import { theme } from "@xometry/xometry_loft";

const wrapperDiv = css`
  box-sizing: border-box;
  background: linear-gradient(0deg, #e6f0fd 0%, #ffffff 100%);
  height: calc(100% - 16px);
  display: flex;
  align-items: center;
  justify-content: center;

  .inner {
    max-width: 300px;
    margin: 0 auto;

    .search-box {
      width: 100%;
      position: relative;
      box-sizing: border-box;

      input {
        width: 100%;
        height: 36px;
        line-height: 40px;
        font-size: 18px;
      }

      i {
        color: #8495a3;
        position: absolute;
        top: 8px;
        right: 5px;
        font-size: 18px;
        cursor: pointer;
      }
    }

    .not-found {
      color: #9e3c3c;
    }
  }

  h2 {
    text-align: center;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 10px;
  }
`;

const shareRequestModalStyle = css`
  position: fixed;
  top: 240px;
  left: 50%;
  border-radius: 4px;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  max-width: 1000px;
  width: auto;
  border: 0px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  background-color: ${theme.colors.white};

  .button-bar {
    display: flex;
    margin-top: 20px;
    button:last-child {
      margin-right: 0;
      margin-left: auto;
    }
  }

  .feedback {
    color: #9e3c3c;
  }

  textarea {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    padding: 4px 8px;
    border-radius: 3px;
    border: 1px solid rgb(132, 149, 163);
    outline: 0px;
    color: rgb(34, 68, 95);
    box-sizing: border-box;
    appearance: none;
  }

  h3 {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export { wrapperDiv, shareRequestModalStyle };
