// eslint-disable-next-line
import React, { useContext, useState } from "react";
import { Panel, Input, Button } from "@xometry/xometry_loft";
import { jsx } from "@emotion/react";
import { origins } from "../../utils/constants";
/** @jsx jsx */
import ShareRequestModal from "./ShareRequestModal";
import { wrapperDiv } from "./QuoteSearch.style";
import { PunchOutContext } from "../PunchOutContext";
import { genericTrack } from "../../utils/analytics";

const QuoteSearch = () => {
  const [isErrorLabelVisible, setIsErrorLabelVisible] = useState<boolean>(
    false
  );
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const { punchOut, punchoutSessionId } = useContext(PunchOutContext);
  const punchoutParams = punchOut
    ? `?punchout=true&punchout_session_id=${punchoutSessionId}`
    : "";

  const searchQuote = (): Promise<any> => {
    const headers = {};
    setIsErrorLabelVisible(false);
    return fetch(`${origins.api}/v2/quotes/${searchText}/quote-search`, {
      method: "GET",
      headers: { ...headers },
      credentials: "include",
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data === true) {
          genericTrack("Quote Search", {
            search: searchText,
            success: 1,
          });
          window.location.href = `${origins.webapp}/quote/${searchText}${punchoutParams}`;
        } else {
          genericTrack("Quote Search", {
            search: searchText,
            success: 0,
          });
          setIsErrorLabelVisible(true);
        }
      })
      .catch(() => {
        genericTrack("Quote Search", {
          search: searchText,
          success: 0,
        });
        setIsErrorLabelVisible(true);
      });
  };

  const handleSearchTextChange = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleSearchTextKeyDown = (event: any) => {
    if (event.key === "Enter") {
      searchQuote();
    }
  };

  const handleSearchIconClick = () => {
    searchQuote();
  };

  return (
    <Panel css={wrapperDiv}>
      <ShareRequestModal
        isOpen={isShareModalVisible}
        onClose={() => setIsShareModalVisible(false)}
        onSubmit={() => {
          setIsErrorLabelVisible(false);
          setSearchText("");
        }}
        quoteId={searchText}
      />
      <div className="inner">
        <h2>Search for an Existing Quote</h2>
        <div className="search-box">
          <Input
            type="text"
            onKeyDown={handleSearchTextKeyDown}
            onChange={handleSearchTextChange}
            placeholder="Search by Quote ID"
          />
          <i
            role="button"
            className="fas fa-search"
            onClick={handleSearchIconClick}
            onKeyDown={handleSearchTextKeyDown}
            title="Search"
            tabIndex={0}
            aria-label="Search"
          />
        </div>
        {isErrorLabelVisible && (
          <div>
            <div className="not-found">The Quote ID could not be found.</div>
            <div>
              <br />
              If this Quote ID is shared with you by someone in your
              organization, Click the Request Access button to send a note to
              the Quote Owner.
              <br />
              <Button
                kind="primary"
                onClick={() => setIsShareModalVisible(true)}
              >
                Request Access
              </Button>
            </div>
          </div>
        )}
      </div>
    </Panel>
  );
};

export default QuoteSearch;
