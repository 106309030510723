import React, { useState, Suspense, ComponentType } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Button, Panel, Spinner } from "@xometry/xometry_loft";

import lazyRetry from "@tdotcode/react-lazy-retry";
import { loadRemoteComponent } from "../../../utils/lazyLoad";
import {
  wrapper,
  componentNotLoaded,
  componentNotLoadedWrapper,
  retryButton,
} from "./QuoteListComponent.style";
import { origins } from "../../../utils/constants";

// TODO: if possibly, generalize this component for all remote components:
const RemoteComponentReload = (props: { handleReload: any }) => (
  <div css={[componentNotLoadedWrapper, wrapper]}>
    {/* show n=2 placeholders */}
    {[...Array(2)].map((_, i) => (
      <Panel kind="primary" css={componentNotLoaded} key={i}>
        <p>We&apos;re having trouble loading your quotes.</p>
        <p>
          <i className="fas fa-rotate-right" />
          <Button onClick={props.handleReload} link css={retryButton}>
            Click to try again
          </Button>
        </p>
      </Panel>
    ))}
  </div>
);

// TODO: if possibly, generalize this component for all remote components:
const RemoteComponentLoading = () => (
  <div css={[componentNotLoadedWrapper, wrapper]}>
    {/* show n=2 placeholders */}
    {[...Array(2)].map((_, i) => (
      <Panel kind="primary" css={componentNotLoaded} key={i}>
        <Spinner />
      </Panel>
    ))}
  </div>
);

const RemoteQuoteListComponent = lazyRetry(
  // retry 3 times with 500ms delay
  async () => {
    type ModuleType = { default: ComponentType<any> };
    return loadRemoteComponent(
      `${origins.home}/quoting/quote/microcomponents/quote-list.mjs`
    ) as Promise<ModuleType>;
  },
  3,
  500
);

const QuoteListWrapper = ({
  reload,
  children,
}: {
  reload: boolean;
  children: any;
}) =>
  reload ? <RemoteComponentLoading /> : <div css={wrapper}>{children}</div>;

export const QuoteListComponent = (props: {
  punchOut: boolean;
  punchoutSessionId?: string;
}) => {
  const { punchOut, punchoutSessionId } = props;
  const [forceReload, setForceReload] = useState(false);
  return (
    <ErrorBoundary
      fallbackRender={({ resetErrorBoundary }) => {
        return (
          <RemoteComponentReload
            handleReload={() => {
              // force the reload of the remote component:
              setForceReload(true);
              resetErrorBoundary?.();
              setTimeout(() => {
                setForceReload(false);
              }, 1000);
            }}
          />
        );
      }}
      onError={err => {
        console.error(err?.message);
      }}
    >
      <QuoteListWrapper reload={forceReload}>
        <Suspense fallback={<RemoteComponentLoading />}>
          <RemoteQuoteListComponent
            punchOut={punchOut}
            punchoutSessionId={punchoutSessionId}
            config={{
              apiOrigin: origins.api,
              authOrigin: origins.auth,
              getOrigin: origins.webapp,
              graphqlEndpoint: "/api/graphql/",
              graphqlOrigin: origins.home,
            }}
          />
        </Suspense>
      </QuoteListWrapper>
    </ErrorBoundary>
  );
};
